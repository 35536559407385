<template>
    <div>
        <Error404 v-if="display404"></Error404>
        <div v-else>
            <header-title-button
                :title="id ? disabledForm ?  'Banner Detail' : 'Edit Banner' : 'Add Banner' "
                :isAdd="false">
            </header-title-button>

            <box
                back="master.banner"
                title="Banner Information"
                :save="!disabledForm"
                :saveText="form.id ? `Edit` : `Save`"
                @onSave="doSaveConfirm"
                :loading="is_loading"
                :remove="false"
                :isSwitch="true"
                :switchValue="form.is_active"
                :switchDisabled="disabledForm"
                switchTitle="Status"
                :switchText="form.is_active ? 'Active' : 'Not Active' "
                @onSwitchButton="changeBtnSwitch">
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="{'has-error': !!errors.name}">
                                <label class="control-label">Name <span class="warning-text-required">*</span></label>
                                <input type="text" class="form-control name"
                                    id="name"
                                    v-model="form.name"
                                    :disabled="disabledForm"
                                    maxlength="100"
                                    autocomplete="off" />

                                    <p class="help help-block" v-if="errors.name">{{  form.name ? `Banner name must be at least three characters, maximum one hundred characters.` : `Banner name is required.` }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" :class="{'has-error': !!errors.file_path}">
                                <label class="control-label">File <span class="warning-text-required">*</span></label>
                                <ImageUpload 
                                    :format_file="format_file"
                                    :image="form.file_path ? form.file_path : ''  "
                                    @onChangeUploadImage="changeUploadImage"
                                    @onChangeDeleteImage="changeDeleteImage"
                                    :disabled="disabledForm"
                                    :max_size="max_size"/>
                            </div>
                        </div>
                    </div>
                    <br>
                    <p>&nbsp;</p>
                    <p class="help help-block warning-text-required" style="margin-top: 30px" v-if="errors.file_path">File is required.</p>
                    <br>
                    <p>&nbsp;</p>

                    <!--  

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Active Link ?</label>
                                <p>When the link is active, clicking the banner will open an external link.</p>
                                <div style="margin-left: -10px;">
                                    <button-switch
                                        title=""
                                        :value="form.is_active_url"
                                        :disabled="disabledForm"
                                        @onClickSwitchButton="changeBtnSwitchActiveUrl"></button-switch>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="form.is_active_url">
                            <div class="form-group" :class="{'has-error': !!errors.url}">
                                <label class="control-label">URL <span class="warning-text-required">*</span></label>
                                <p>Define where the banner link to when clicked by user.</p>
                                <input type="text" class="form-control url"
                                    id="url"
                                    v-model="form.url"
                                    :disabled="disabledForm"
                                    autocomplete="off" />

                                    <p class="help help-block" v-if="errors.url">{{  this.form.url ? `Invalid url format.` : `Url is required.` }}</p>
                            </div>
                        </div>
                    </div>

                    -->

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="{'has-error': !!errors.description}">
                                <label class="control-label">Description <span class="warning-text-required">*</span> </label>
                                <textarea 
                                    class="form-control description" 
                                    id="description" 
                                    v-model="form.description"
                                    maxlength="255"
                                    rows="7"
                                    :disabled="disabledForm">
                                </textarea>
                                <p>{{ calculate_description }}/255</p>

                                <p class="help help-block" v-if="errors.description">{{  form.description ? `Description must be at least three characters, maximum two hundred fifty-five characters.` : `Description is required.` }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="{'has-error': !!errors.banner_location_id}">
                                <label class="control-label">Location <span class="warning-text-required">*</span> </label>
                                <div class="form-group">
                                    <div class="row">
                                        <div v-for="(data, i) in banner_location" :key="i"  style="padding: 15px; margin-bottom: 5px; display: inline;">
                                            <div class="div-banner-location-select cursor-pointer" @click="selectBannerLocation(i)">
                                                <div class="icheck-primary">
                                                    <input type="radio" :id="'radio-select-banner-location-'+i"
                                                        name="input-banner-location-id"
                                                        :value="data.id"
                                                        v-model="form.banner_location_id"
                                                        :disabled="disabledForm || !data.is_active"
                                                        />
                                                    <label :for="'r'+i" style="margin-left: 44%; margin-top: 3%; margin-bottom: 1%;">&nbsp;</label>
                                                    <p class="text-center">{{ data.name }}</p>
                                                    
                                                </div>
                                                <img v-if="data.code == 'IDLE' && data.is_active" 
                                                    src="@/assets/svg/banner/icon-banner-idle-screen.svg"
                                                   class="img-banner-location"/>
                                                <img v-else-if="data.code == 'IDLE' && !data.is_active" 
                                                    src="@/assets/svg/banner/icon-banner-idle-screen-unavailable.svg"
                                                    class="img-banner-location" />

                                                <img v-if="data.code == 'TOP' && data.is_active" 
                                                    src="@/assets/svg/banner/icon-banner-top-active-screen.svg"
                                                   class="img-banner-location"/>
                                                <img v-else-if="data.code == 'TOP' && !data.is_active" 
                                                    src="@/assets/svg/banner/icon-banner-top-active-screen-unavailable.svg"
                                                    class="img-banner-location" />

                                                <img v-if="data.code == 'BOTTOM' && data.is_active" 
                                                    src="@/assets/svg/banner/icon-banner-bottom-active-screen.svg"
                                                   class="img-banner-location"/>
                                                <img v-else-if="data.code == 'BOTTOM' && !data.is_active" 
                                                    src="@/assets/svg/banner/icon-banner-bottom-active-screen-unavailable.svg"
                                                    class="img-banner-location" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="help help-block" v-if="errors.banner_location_id">Location is required.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <h4 class="text-bold">Live Date</h4>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-error': !!errors.start_date}">
                            <label class="control-label">Start Date  <span class="warning-text-required">*</span> </label>
                            <datepicker 
                                v-model="form.start_date"
                                :formatDate="'YYYY-MM-DD'"
                                :config="dateStartConfig"
                                :disabled="disabledForm"
                                id="start_date"
                            />
                            <p class="help help-block" v-if="errors.start_date">{{ !id && form.start_date && form.start_date < moment().format('YYYY-MM-DD') ?   `The start date must be later than or equal to today` : `Start date is required.`}}</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-error': !!errors.end_date}">
                            <label class="control-label">End Date <span class="warning-text-required">*</span> </label>
                            <datepicker 
                                v-model="form.end_date"
                                :formatDate="'YYYY-MM-DD'"
                                :config="dateEndConfig"
                                :disabled="disabledForm"
                                id="end_date"
                            />

                            <p class="help help-block" v-if="errors.end_date">End date is required.</p>
                        </div>
                    </div>
                </div>
            </box>
        </div>

        <modal :title="form.id ? 'Change Confirmation' : 'Add Confirmation' " save="true" remove="false"
            :show="is_confirm"
            :saveText=" form.id ? 'Yes, Confirmation' : 'Save'"
            @hidden="is_confirm = false"
            @onSave="doSave()"
            :loading="is_confirm_loading">
            <span class="span-title">Are you sure you want to modify the banner ?</span>
        </modal>
    </div>
</template>

<script>
import Error404 from '@pages/Error404.vue'
import ImageUpload from '@app/components/ImageUpload.vue'
import ButtonSwitch from '@app/components/ButtonSwitch.vue'
import moment from 'moment'
const {console} = window
export default {
    components: {
        Error404,
        ImageUpload,
        ButtonSwitch
    },
    data() {
        return {
            alias: 'banner',
            id: this.$route.params.id,
            is_edit: false,
            disabledForm: false,
            display404: false,
            page: {
                title: 'Nuanu',
                subtitle: 'Banner'
            },
            form: {
                id: this.$route.params.id,
                name: null,
                file_path: null,
                is_active_url: false,
                url: null,
                start_date: null,
                end_date: null,
                banner_location_id: null,
                is_active: true,
                description: null,
            },
            errors: {
                name: false,
                file_path: false,
                description: false,
                url: false,
                banner_location_id: false,
                start_date: false,
                end_date: false,
            },
            is_confirm: false,
            is_confirm_loading: false,
            is_loading: false,
            is_error_upload: false,
            banner_location: [],
            file: null,
            dateStartConfig: {
                minDate: moment().format('YYYY-MM-DD'),
                locale: {
                    format: 'YYYY-MM-DD'
                }
            },
            dateEndConfig: {
                locale: {
                    format: 'YYYY-MM-DD'
                }
            },
            format_file: "video/mp4",
            is_first_load: true,
            start_date_temp: null,
            max_size: 50000000
        }
    },
    async mounted() {
        this.$root.checkingAccessPage(this.$root._route.name)
        if(this.id)  {
            switch(this.$route.query.is_edit) {
                case true: 
                    this.is_edit = true 
                    this.disabledForm = false
                    break;
                case false: 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
                case 'true': 
                    this.is_edit = true 
                    this.disabledForm = false
                    break;
                case 'false': 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
                default: 
                    this.is_edit = false 
                    this.disabledForm = true
                    break;
            } 

            if(!this.is_edit) {
                this.$store.state.title = 'Banner Detail'
            }else {
                let permission_edit = await this.$root.checkingButtonsPage(this.alias, 'update')
                if (!permission_edit.validate) {
                    this.disabledForm = true 
                }
            }

            this.init()

        }else {
            this.is_first_load = false
            let permission_create = await this.$root.checkingButtonsPage(this.alias, 'create')
            if(!permission_create.validate) {
                this.disabledForm = true 
            }
            
            this.form.end_date = null
            this.form.start_date = null
            $('#start_date input').val('')
            $('#end_date input').val('')
            
        }

        this.getDataBannerLocation()

        let self = this
        $(document).ready(function(e) {
            $('.select-banner-location').on('select2:select', (e) => {
                e.preventDefault();
                let data = e.params.data
                self.form.banner_location_id = data.id
            })
        })

        $('.select2').select2()
        $('.select-banner-location').select2()

        // this.refreshStartDate()

    },
    methods: {
        async getDataBannerLocation() {
            Rest.get('/banner-location?size=100')
            .then( (res) => {
                if(res && res.data && res.data.items && res.data.items.length > 0) {
                    this.is_first_load = true
                    let idle = null
                    let top = null 
                    let bottom = null 
                    res.data.items.forEach( (item) => {
                        switch (item.code) {
                            case 'IDLE':
                                idle = item
                                break;
                            case 'TOP':
                                top = item 
                                break;
                            case 'BOTTOM':
                                bottom = item
                                break;
                            default:
                                break;
                        }
                    })

                    let banner_location = []
                    if(idle) {
                        banner_location.push(idle)
                    }

                    if(top) {
                        banner_location.push(top)
                    }

                    if(bottom) {
                        banner_location.push(bottom)
                    }
                    this.banner_location = banner_location
                    $('.select2').select2()
                    $('.select-banner-location').select2()
                }
            })
            .catch( () => {

            })
        },
        async init() {
            if(this.id) {
                Rest.get('/banner/'+this.id)
                .then( async(res) => {
                    this.form.name = res.data.name
                    this.form.is_active = res.data.is_active
                    this.form.description = res.data.description
                    this.form.file_path = res.data.file_path
                    this.form.is_active_url = res.data.is_active_url
                    this.form.url = res.data.url
                    if(res.data.start_date) {
                        this.form.start_date = res.data.start_date
                        this.start_date_temp = res.data.start_date
                        this.refreshStartDate()
                    }

                    if(res.data.end_date) {
                        this.form.end_date = res.data.end_date
                    }

                    if(res.data.banner_location_id) {
                        this.form.banner_location_id = res.data.banner_location_id
                        $('.select-banner-location').val([res.data.banner_location_id]).trigger('change')
                    }
                })
            }
        },
        changeBtnSwitchActiveUrl() {
            this.form.is_active_url = !this.form.is_active_url
        },
        changeBtnSwitch() {
            this.form.is_active = !this.form.is_active
        },
        async validationForm() {
            let is_success = true 
            this.errors.name = false
            this.errors.file_path = false 
            this.errors.description = false
            this.errors.url = false
            this.errors.start_date = false 
            this.errors.end_date = false 
            if (!this.form.name) {
                this.errors.name = true 
                is_success = false 
            }else {
                let length = this.form.name 
                if(length < 3 || length > 100) {
                    this.errors.name = true 
                    is_success = false 
                }
            }

            if(!this.form.file_path) {
                if(!this.file) {
                    this.errors.file_path = true 
                    is_success = false 
                }
            }

            if(!this.form.description) {
                this.errors.description = true
                is_success = false 
            }else {
                let length = this.form.description 
                if(length < 3 || length > 255) {
                    this.errors.description = true 
                    is_success = false 
                }
            }

            if(!this.form.banner_location_id) {
                this.errors.banner_location_id = true
                is_success = false
            }

            if(this.form.is_active_url) {
                if(!this.form.url) {
                    this.errors.url = true 
                    is_success = false 
                }else {
                    let is_valid = this.$root.validationInputUrl(this.form.url)
                    if(!is_valid) {
                        this.errors.url = true 
                        is_success = false
                    }
                }
            }

            if(!this.form.start_date) {
                this.errors.start_date = true 
                is_success = false 
            }else {
                if(!this.id) {
                    let now = moment().format('YYYY-MM-DD')
                    if(this.form.start_date < now) {
                        this.errors.start_date = true 
                        is_success = false 
                    }
                }
            }

            if(!this.form.end_date) {
                this.errors.end_date = true 
                is_success = false 
            }

            if (this.form.start_date && this.form.end_date) {
                if(moment(this.form.end_date) < moment(this.form.start_date)) {
                    this.errors.end_date = true 
                    is_success = false
                }
            }

            return is_success
        },
        async doSaveConfirm() {
            let is_valid = await this.validationForm()
            console.log('is_valid :', is_valid)
            console.log('errors', this.errors)
            if(is_valid) {
                if(this.id) {
                    this.is_confirm = true 
                }else {
                    if(this.file) {
                        await this.processUploadImage()
                        if(this.is_error_upload) {
                            return 
                        }
                    }else {
                        if(this.form.file_path) {
                            this.doSave()
                        }
                    }
                }
            }
        },
        async doSave() {
            this.is_confirm_loading = false
            if(this.file) {
                await this.processUploadImage()
                if(this.is_error_upload) {
                    this.is_confirm = false 
                    this.is_confirm_loading = false 
                }
            }else {
                this.doSaveProcess()
            }
        },
        async doSaveProcess() {
            // if(this.form.file) {
                let params = {
                    name: this.form.name,
                    description: this.form.description,
                    file_path: this.form.file_path,
                    banner_location_id: this.form.banner_location_id,
                    is_active_url: this.form.is_active_url,
                    url: this.form.url,
                    start_date: this.form.start_date,
                    end_date: this.form.end_date,
                    is_active: this.form.is_active
                }

                let method = this.id ? 'put' : 'post'
                let url = this.id ? '/banner/'+this.id : '/banner'
                Rest[method](url, params)
                .then( async(res) => {
                    if(res && res.status == 200) {
                        this.$root.showNotification('Success', 'success', this.id ? "Banner modification successful." : "Banner addition successful.")

                        this.userLogActivity()

                        if(!this.id) {
                            if(res.data && res.data.id) {
                                this.form.id = res.data.id
                            }
                        }
                        
                        this.$root.$router.push({
                            name: "master.banner"
                        })
                    }
                })
                .catch( (err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "Banner addition failed"
                            if(this.id) {
                                message = "Banner modification failed."
                            }
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                })
                .finally( () => {
                    this.is_confirm = false 
                    this.is_confirm_loading = false
                    this.is_loading = false
                })
            // }
        },
        async userLogActivity() {
            if(this.$root.$store.state.user && this.$root.$store.state.user.full_name) {
                let route_name = {
                    name: 'master.ticket-package.form',
                    params: {
                        id: this.form.id
                    },
                    query: {
                        is_edit: false
                    }
                } 

                let secretKey = process.env.VUE_APP_KEY_ENCRYPT_DECRYPT
                let route_name_encrypt = this.EncryptDecryptAesCbc256.encryptAesCbc256(secretKey, JSON.stringify(route_name))

                let activity_type = 'create'
                let activity = `${this.$root.$store.state.user.full_name} has added a banner with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                if(this.id) {
                    activity = `${this.$root.$store.state.user.full_name} has modified the banner with the name <a style="cursor: pointer" class="click-detail-log" data-route="${route_name_encrypt}"> ${this.form.name}</a>`
                    activity_type = 'edit'
                }

                let params = {
                    activity: activity,
                    feature: 'Banner',
                    activity_type: activity_type,
                    route_name: route_name
                }

                this.$root.sendUserLogActivity(params)
            }
            
        },   
        changeUploadImage(data) {
            this.form.file_path = ""
            this.file = data.file  
            this.errors.file_path = false 
        },
        changeDeleteImage() {
            this.form.file_path = ""
            this.file = null 
            this.errors.file_path = true
        },
        async processUploadImage() {
            this.is_error_upload = false
            if(this.file) {
                var params = new FormData();
                params.append('files', this.file)
                Rest.post('/storage/files', params)
                .then( async(res) => {
                    if(res && res.data && res.data.length > 0 ) {
                        this.form.file_path = res.data[0].path
                        this.file = null

                        this.doSaveProcess()
                    }
                })
                .catch( (err) => {
                    if(err && err.response && err.response.status) {
                        if(err.response.data && err.response.data.message) {
                            this.$root.showNotification('Failed', 'error', err.response.data.message)        
                        }else {
                            var message = "File banner upload failed"
                            this.$root.showNotification('Failed', 'error', message)
                        }
                    }else {
                        this.$root.showNotification('Failed', 'error', 'Sorry, you do not have an internet connection.')
                    }
                    
                    this.is_error_upload = true
                })
            }
        },
        async selectBannerLocation(i) {
            $('#radio-select-banner-location-'+i).click()
            console.log('this.form.banner_location_id', this.form.banner_location_id)
        },
        async refreshStartDate() {
            let min_date = moment().format('YYYY-MM-DD')
            if(this.id && this.start_date_temp) {
                min_date = moment(this.start_date_temp).format('YYYY-MM-DD')
            }

            this.dateStartConfig = {
                minDate: min_date,
                locale: {
                    format: 'YYYY-MM-DD'
                }
            }
        },
        async refreshEndDate() {
            
            this.dateEndConfig = {
                minDate: moment(this.form.start_date).format('YYYY-MM-DD'),
                locale: {
                    format: 'YYYY-MM-DD'
                }
            }
        
            console.log('refreshEndDate :', this.is_first_load)
            if(!this.is_first_load) {
                this.form.end_date = null 
                const delay = ms => new Promise(res => setTimeout(res, ms))
                await delay(0.001);
                $('#end_date input').val('') 
            }

            this.is_first_load = false
           
        },
    },
    computed: {
        calculate_description() {
            let length = 0
            if(this.form.description) {
                length = this.form.description.length
            }
            return length
        },
    },
    watch: {
        'form.name': {
            handler(val) {
                this.errors.name = false
                if(!val) {
                    this.errors.name = true 
                }else {
                    let length = val.length
                    if(length < 3 || length > 100) {
                        this.errors.name = true
                    }
                }
            }
        },
        'form.description': {
            handler(val) {
                this.errors.description = false 
                if(!val) {
                    this.errors.description = true
                }else {
                    let length = val.length
                    if(length < 3 || length > 255) {
                        this.errors.description = true
                    }
                }
            }
        },
        'form.is_active_url': {
            handler(val) {
                this.errors.url = false 
                if(val) {
                    if(!this.form.url) {
                        this.errors.url = true 
                    }else {
                        let is_valid = this.$root.validationInputUrl(this.form.url)
                        if(!is_valid) {
                            this.errors.url = true 
                        }
                        
                    }
                }else {
                    this.form.url = null 
                }
            }
        },
        'form.url': {
            handler(val) {
                this.errors.url = false 
                if(this.form.is_active) {
                    if(!val) {
                        this.errors.url = true 
                    }else {
                        let is_valid = this.$root.validationInputUrl(val)
                        if(!is_valid) {
                            this.errors.url = true 
                        }
                        
                    }
                }
            }
        },
        'form.banner_location_id': {
            handler(val) {
                this.errors.banner_location_id = false 
                if(!val) {
                    this.errors.banner_location_id = true 
                }
            }
        },
        'form.start_date': {
            handler(val) {
                if(!this.id) {
                    this.is_first_load = false 
                }

                this.errors.start_date = false 
                if(!val) {
                    this.errors.start_date = true 
                }else {
                    if(!this.id) {
                        let now = moment().format('YYYY-MM-DD')
                        if(val < now) {
                            this.errors.start_date = true 
                        }
                    }
                    this.refreshEndDate()
                }
            }
        },
        'form.end_date': {
            handler(val) {
                this.errors.end_date = false 
                if(!val) {
                    this.errors.end_date = true 
                }else {
                    if(this.form.start_date) {
                        if(moment(val) < moment(this.form.start_date)) {
                            this.errors.end_date = true 
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
textarea {
    resize: none;
}

.div-banner-location-select {
    display: inline-block;
    width: 214px;
    height: 210px;
    gap: 10px;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #6147FF
}

.icheck-primary > input:first-child:checked + label::before, .icheck-primary > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #6147FF;
    border-color: #6147FF;
}
.img-banner-location {
    width: 150px; 
    height:120px; 
    padding-left: 26%;
}
</style>